<template>
  <div class="event mb-3">
    <div class="row">
      <div class="col-3 date">
        <div>
          <span v-if="obj.date.fullDate">{{ getTranslatedMonth(obj.date.fullDate) }}</span>
          <span v-if="obj.date.day">{{ obj.date.day }}</span>
          <span v-if="obj.date.year">{{ obj.date.year }}</span>
        </div>
      </div>
      <div class="col-9 content">
        <h3 class>{{ charLimit(obj.title, 45) }}</h3>
        <div class="text">
          <p v-if="obj.content">{{ charLimit(obj.content, 25) }}</p>
          <p v-if="obj.speaker">{{ obj.speaker }}</p>
          <p v-if="obj.offer">{{ obj.offer }}</p>
          <p v-if="obj.notes">{{ i18n.tcNotes }}: {{ obj.notes }}</p>
          <p v-if="obj.date.time" class="time">Saturday - at {{ obj.date.time }}</p>
        </div>
        <a v-if="obj.ink" href="#">{{ i18n.tcView }}</a>
        <a v-if="obj.editLink" @click.prevent="handleEditClick" href="#">{{ i18n.tcMore }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
/* eslint-disable */
export default {
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          tcMore: 'More',
          tcView: 'View',
        }
      },
    },
    obj: Object,
  },
  methods: {
    async handleEditClick() {
      this.$emit('edit_requested', this.obj)
    },
    charLimit(text, limit) {
      return text.length > limit ? text.substring(0, limit) + '...' : text
    },
    getTranslatedMonth(date) {
      var objDate = new Date(date)
        var month = objDate.toLocaleString(this.prefCulture, { month: 'short' })
      return month;
    }
  },
  computed: {
    ...mapGetters({      
      prefCulture: 'user/userPreferredCulture',
    })
  }
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/components/component-event.scss';
</style>
